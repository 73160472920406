"use client";

import React, { useEffect, useState } from "react";
import { MultipleEventsLayout } from "@/components/sections/monthly-event/MultipleEventsLayout";
import { SingleEventLayout } from "@/components/sections/monthly-event/SingleEventLayout";

type Event = {
  id: number;
  titre: string;
  description: string;
  image: string;
  dateDebut: string;
  dateFin: string;
  nomMagasin: string[];
  codePromo: string;
};

type FormattedEvent = {
  title: string;
  description: string;
  image: string;
  period: string;
  salons: string[];
  promoCode: string;
};

export function MonthlyEventSection() {
  const [events, setEvents] = useState<FormattedEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchEvents() {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/events`,
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des événements");
        }

        const data = (await response.json()) as Event[];

        const formattedEvents = data.map((event): FormattedEvent => {
          const debut = new Date(event.dateDebut);
          const fin = new Date(event.dateFin);

          const formatDate = (date: Date) => {
            const day = date.getDate();
            const month = date.toLocaleString("fr-FR", { month: "long" });
            return `${day} ${month}`;
          };

          return {
            title: event.titre.toUpperCase(),
            description: event.description,
            image: event.image,
            period: `${formatDate(debut)} - ${formatDate(fin)}`,
            salons: event.nomMagasin,
            promoCode: event.codePromo,
          };
        });

        setEvents(formattedEvents);
      } catch (error) {
        console.error("Erreur lors du chargement des événements:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchEvents();
  }, []);

  if (isLoading || !events.length) {
    return null;
  }

  return events.length === 1 ? (
    <SingleEventLayout event={events[0]} />
  ) : (
    <MultipleEventsLayout events={events} />
  );
}
