"use client";

import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Collections } from "@/(global)/collections/data/collections";
import { MyNauiLongArrowRight } from "@/assets/icons/mynaui-long-arrow-right";

const formatDateToFrench = (dateStr: string) => {
  const [month, year] = dateStr.split("/");
  const months = {
    "01": "Janvier",
    "02": "Février",
    "03": "Mars",
    "04": "Avril",
    "05": "Mai",
    "06": "Juin",
    "07": "Juillet",
    "08": "Août",
    "09": "Septembre",
    "10": "Octobre",
    "11": "Novembre",
    "12": "Décembre",
  };

  return `${months[month as keyof typeof months]} 20${year}`;
};

export const CollectionsSection: React.FC = () => {
  const recentCollections = [...Collections]
    .sort((a, b) => {
      const [monthA, yearA] = a.date.split("/").map(Number);
      const [monthB, yearB] = b.date.split("/").map(Number);
      if (yearA !== yearB) return yearB - yearA;
      return monthB - monthA;
    })
    .slice(0, 3);

  return (
    <div className="flex w-full flex-col gap-[3rem] px-4 py-20 ph:px-24 md:gap-0 md:px-48 lg:px-72 xl:px-96">
      <div className="flex w-full flex-col justify-between md:flex-row">
        <h2 className="mb-2 font-cormorant text-4xl text-primary ph:text-5xl sm:text-6xl md:mb-12 lg:mb-16 lg:text-7xl">
          COLLECTIONS
        </h2>
        <Link
          href="/collections"
          className="inline-flex flex-row items-center gap-2 text-sm text-primary transition-colors hover:text-gold xs:ml-1 ph:text-base md:mb-5 md:ml-0 lg:mb-6"
        >
          Voir toutes les collections
          <MyNauiLongArrowRight className="h-4 w-4 ph:h-6 ph:w-6" />
        </Link>
      </div>

      <div className="grid gap-16 sm:grid-cols-2 lg:grid-cols-3">
        {recentCollections.map((collection, index) => (
          <Link
            href={`/collections/${collection.id}`}
            key={index}
            className="group"
          >
            <div className="flex flex-col gap-4">
              <div className="relative aspect-square w-full overflow-hidden">
                <Image
                  src={collection.images[0].src}
                  alt={collection.title}
                  fill
                  sizes="(max-width: 768px) 100vw, 50vw"
                  className="object-cover transition-transform duration-500 ease-out group-hover:grayscale"
                />
              </div>
              <div className="flex flex-col items-center text-center">
                <h2 className="text-xl font-medium">{collection.title}</h2>
                <p className="text-sm text-gray-600">
                  {formatDateToFrench(collection.date)}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
