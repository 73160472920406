import(/* webpackMode: "eager", webpackExports: ["CollectionsSection"] */ "/vercel/path0/frontend/app/(landing-page)/sections/CollectionsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/vercel/path0/frontend/app/(landing-page)/sections/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonthlyEventSection"] */ "/vercel/path0/frontend/app/(landing-page)/sections/MonthlyEventSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrestationsSection"] */ "/vercel/path0/frontend/app/(landing-page)/sections/PrestationsSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/assets/images/salons/choisy-le-roi.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/assets/images/salons/salon-au-grey-des-couleurs.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/assets/images/salons/salon-villejuif.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/app/assets/images/salons/ville-d-avray.webp");
;
import(/* webpackMode: "eager", webpackExports: ["FindUsSection"] */ "/vercel/path0/frontend/app/components/sections/find-us/FindUsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GiftCardSection"] */ "/vercel/path0/frontend/app/components/sections/GiftCardSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewsSection"] */ "/vercel/path0/frontend/app/components/sections/reviews/ReviewsSection.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@iconify/react/dist/iconify.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
